<template>
  <div>
    <small class="d-block mb-1 d-flex align-items-center">
      <b-icon class="h4 mr-2" icon="exclamation-circle"></b-icon>
      SESMET - resposta em SIM, anexar cópia protocolo de registro do SESMET no Ministério do Trabalho
    </small>
    <b-list-group flush>
      <b-list-group-item class="mb-3 px-md-0">
        <div>
          <span>
            Setor de Segurança e Medicina do trabalho é próprio ou Terceirizado?
          </span>
          <div class="mt-2">
            <b-button size="sm" @click="handleChangeSesmet('is_outsourcing', false)"
              :variant="!sesmet.is_outsourcing ? 'secondary' : 'light'">
              Próprio
            </b-button>
            <b-button size="sm" @click="handleChangeSesmet('is_outsourcing', true)"
              :variant="sesmet.is_outsourcing ? 'secondary' : 'light'" class="ml-2">
              Terceirizado
            </b-button>
          </div>
        </div>

        <b-card v-if="sesmet.is_outsourcing" class="mt-3">
          <b-form-group label="CNPJ da Empresa">
            <b-input v-model="sesmet.outsourcing_cnpj" v-mask="'##.###.###/####-##'" @change="
              handleChangeSesmet('outsourcing_cnpj', sesmet.outsourcing_cnpj)
              " />
          </b-form-group>
          <b-form-group :label="`E-mail de contato`">
            <b-input v-model="sesmet.outsourcing_email" @change="
              handleChangeSesmet(
                'outsourcing_email',
                sesmet.outsourcing_email
              )
              " />
          </b-form-group>
        </b-card>
      </b-list-group-item>
      <b-list-group-item class="mb-3 px-md-0">
        <div>
          <span> A empresa cumpre com SESMET de acordo com lei? </span>
          <div class="mt-2">
            <b-button size="sm" @click="handleChangeSesmet('has_sesmet', true)"
              :variant="sesmet.has_sesmet ? 'secondary' : 'light'">
              Sim
            </b-button>
            <b-button size="sm" @click="handleChangeSesmet('has_sesmet', false)"
              :variant="!sesmet.has_sesmet ? 'secondary' : 'light'" class="ml-2">
              Não
            </b-button>
          </div>
        </div>

        <b-card v-if="sesmet.has_sesmet" class="mt-3">
          <b-form-group label="Nome da pessoa responsável">
            <b-input v-model="$v.sesmet.sesmet_responsible_name.$model"
              :class="!$v.sesmet.sesmet_responsible_name.required && 'is-invalid'" @change="
                handleChangeSesmet(
                  'sesmet_responsible_name',
                  sesmet.sesmet_responsible_name
                )
                " />
            <b-form-invalid-feedback v-if="!$v.sesmet.sesmet_responsible_name.required">
              Esse campo é obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="E-mail de contato representante">
            <b-input v-model="$v.sesmet.sesmet_responsible_email.$model"
              :class="!$v.sesmet.sesmet_responsible_email.email || !$v.sesmet.sesmet_responsible_email.required ? 'is-invalid' : ''"
              @change="
                handleChangeSesmet(
                  'sesmet_responsible_email',
                  sesmet.sesmet_responsible_email
                )
                " />
            <b-form-invalid-feedback force-show="true"
              v-if="!$v.sesmet.sesmet_responsible_email.email || !$v.sesmet.sesmet_responsible_email.required">
              {{ $t("auth.type-valid-email") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-col v-if="sesmet.has_sesmet" class="px-0" md="3">
            <b-button size="sm" :disabled="checkFieldsSesmet()"
              :variant="Object.keys(proof_file).length > 0 ? 'primary' : 'info'" @click="handleAddProof()" target="_blank"
              class="w-100">
              <div v-if="Object.keys(proof_file).length > 0">
                <b-icon icon="patch-check" class="mr-2" />
                <span class="text-nowrap">Certificado Anexado</span>
              </div>
              <div v-else>
                <b-icon icon="paperclip" />
                <span class="text-nowrap">Anexar Comprovante</span>
              </div>
            </b-button>
          </b-col>
        </b-card>
      </b-list-group-item>
    </b-list-group>
    <b-modal id="add-proof-payment" size="md" hide-footer centered title="Anexar Comprovante">
      <b-card no-body class="p-2" v-if="Object.keys(this.proof_file).length > 0">
        <span class="d-block mb-2">
          comprovante anexado em
          {{ proof_file.created_at | dateFull }}
        </span>
        <a class="text-left d-flex align-items-center line-height-1" v-bind:href="proof_file.file" target="_blank"
          download>
          <b-icon icon="paperclip" class="mr-2 m-0" />
          <span> {{ proof_file.name }}</span>
        </a>
      </b-card>

      <file-upload @upload="handleUploadProof" />
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import FileUpload from "@/components/FileUpload";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    FileUpload
  },
  mixins: [validationMixin],
  data() {
    return {
      sesmet: {},
      hasOutsourced: false,
      hasSesmt: false,
      proof_file: {}
    };
  },
  validations: {
    sesmet: {
      sesmet_responsible_email: {
        required,
        email
      },
      sesmet_responsible_name: {
        required
      },
    },
  },
  methods: {
    ...mapActions(["edit_sesmets", "get_all_sesmets"]),

    clearInputs() {
      let payload = this.sesmet;
      if (!payload.is_outsourcing) {
        payload.outsourcing_email = "";
        payload.outsourcing_cnpj = "";
      }
      if (!payload.has_sesmet) {
        payload.sesmet_responsible_name = "";
        payload.sesmet_responsible_email = "";
      }
      return payload;
    },
    handleChangeSesmet(variable, value) {
      this.sesmet[variable] = value;
      const {
        has_sesmet,
        is_outsourcing,
        outsourcing_cnpj,
        outsourcing_email,
        sesmet_responsible_email,
        sesmet_responsible_name,
      } = this.sesmet;

      if (is_outsourcing && !(outsourcing_cnpj && outsourcing_email)) {
        return;
      }

      if (
        has_sesmet &&
        !(sesmet_responsible_email && sesmet_responsible_name)
      ) {
        return;
      }

      const data = this.clearInputs();
      if (has_sesmet && Object.keys(this.proof_file).length > 0) {
        data['files'] = [this.proof_file.id]
      } else {
        this.proof_file = {};
        data['files'] = []
      }
      this.edit_sesmets({
        ...data,
        outsourcing_cnpj: outsourcing_cnpj?.replace(/[^\d]/g, ""),
      });
      this.$forceUpdate();
      this.$bvModal.hide("add-proof-payment");
    },
    handleHasOutsourced(type) {
      this.hasOutsourced = type;
    },
    handleHasSesmt(type) {
      this.hasSesmt = type;
    },
    handleAddProof() {
      this.$bvModal.show("add-proof-payment");
    },
    handleUploadProof(files) {
      this.proof_file = files[0]
      this.handleChangeSesmet('has_sesmet', this.sesmet.has_sesmet); // Envia novamente para salvar o files
    },
    checkFieldsSesmet() {
      let value = false
      if (!this.$v.sesmet.sesmet_responsible_email.email ||
        !this.$v.sesmet.sesmet_responsible_email.required ||
        !this.$v.sesmet.sesmet_responsible_name.required) {
        value = true
      }
      return value
    }
  },
  mounted() {
    this.get_all_sesmets().then((response) => {
      this.sesmet = response;
      if (response.files.length > 0) {
        this.proof_file = response.files[0]
      }
    });
  },
};
</script>
